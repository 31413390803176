<template>
  <div class="d-flex flex-column w-100 shadow-sm rounded" v-background-2>
    <div class="d-flex flex-column justify-content-center align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <div>Chargement...</div>
    </div>
    <div v-if="!loading" class="flex-grow-1 d-flex flex-column">
      <div class>
        <div class="d-flex justify-content-between align-items-center">
          <h2>
            <strong>{{ indicator }}</strong>
          </h2>
          <div v-if="progress && progress.state == true" class="d-flex align-items-center text-success">
            <BaseIcon name="arrow-up" width="20" height="20" class="m-0" v-if="icon"></BaseIcon>
            <span v-if="progress && progress.val">{{ progress.val }}</span>
          </div>
          <div v-if="progress && progress.state == false" class="d-flex align-items-center text-danger">
            <BaseIcon name="arrow-down" width="20" height="20" class="m-0" v-if="icon"></BaseIcon>
            <span v-if="progress && progress.val">{{ progress.val }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <BaseIcon :name="icon" width="20" height="20" class="m-0" v-if="icon"></BaseIcon>
          <div class="ml-2" style="line-height: 0.9rem">
            <div>
              <small>{{ title }}</small>
            </div>
            <div class="text-muted">
              <small>{{ text }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/bases/Loader.vue";
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  components: {
    Loader,
    BaseIcon,
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    color: function () {
      return this.$store.getters["colors/colors"].color3;
    },
    colorIcon: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "#d2d5d8";
      } else {
        return "#6c757d";
      }
    },
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    indicator: {
      type: [String, Number],
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    img: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    progress: {
      type: Object,
      required: false,
    },
  },
};
</script>
<style lang="css"></style>
