<template>
  <div class>
    <div class="d-flex flex-row align-items-center justify-content-between mb-3">
      <h3 class="m-0">Tableau de bord</h3>
      <div class>
        <modal
          id="modal_add_collaborateur"
          title="Ajouter un collaborateur"
          btn="Ajouter un collaborateur"
          btnClass="btn btn-primary"
          ref="AddCollaborateur"
        >
          <template v-slot:form>
            <div class="d-flex align-items-center">
              <div class="d-none d-lg-block">
                <img src="/images/collaborateur.svg" alt="" style="height: 15rem" class="m-5" />
              </div>
              <base-form @click="storeCollaborateur" :loading="loading" class="p-3 pl-lg-5 pr-lg-5 rounded">
                <base-input
                  class="pb-4"
                  v-model="collaborateur.collaborateur_nom"
                  inputText="Nom"
                  :errors="feedback.collaborateur_nom"
                ></base-input>
                <base-input
                  class="pb-4"
                  v-model="collaborateur.collaborateur_prenom"
                  inputText="Prénom"
                  :errors="feedback.collaborateur_prenom"
                ></base-input>
                <base-input-date-picker
                  class="pb-4"
                  v-model="collaborateur.collaborateur_date_naissance"
                  inputText="Date de naissance"
                  :errors="feedback.collaborateur_date_naissance"
                ></base-input-date-picker>
                <base-input
                  class="pb-4"
                  v-model="collaborateur.collaborateur_email_pro"
                  inputText="Email collaborateur"
                  :errors="feedback.collaborateur_email_pro"
                ></base-input>
                <base-input
                  class="pb-4"
                  v-model="password1"
                  inputText="Mot de passe"
                  inputType="password"
                  :errors="feedback.password"
                ></base-input>
                <base-input
                  class="pb-4"
                  v-model="password2"
                  inputText="Répéter le mot de passe"
                  inputType="password"
                  :errors="feedback.password"
                ></base-input>
              </base-form>
            </div>
          </template>
        </modal>
      </div>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <qs-loader class="m-5"></qs-loader>
      <h2 class="m-0 d-none d-md-block">Chargement...</h2>
    </div>
    <div v-if="!loading" class>
      <div class="d-flex flex-column flex-xl-row justify-content-between p-md-0" v-if="data">
        <card
          :indicator="data.collaborateurs_actifs"
          :loading="loading"
          title="Collaborateurs actifs"
          img="/user.svg"
          icon="users"
          class="mr-3 mb-3 p-3"
          :progress="IndicatorProgress"
        ></card>
        <card
          :indicator="data.collaborateurs_entrees + ' / ' + data.collaborateurs_sorties"
          :loading="loading"
          title="Entrées / Sorties du mois"
          img="/enter.svg"
          icon="activity"
          class="mr-3 mb-3 p-3"
        ></card>
        <card
          v-if="data.collaborateurs_femmes"
          :indicator="(data.collaborateurs_femmes * 100).toFixed(0) + ' %'"
          :loading="loading"
          title="De femme"
          img="/intersex.svg"
          icon="pie-chart"
          class="mr-3 mb-3 p-3"
        ></card>
        <card
          v-if="data.collaborateurs_age"
          :indicator="data.collaborateurs_age.toFixed(0) + ' ans'"
          :loading="loading"
          title="Age moyen"
          img="/gift.svg"
          icon="gift"
          class="p-3 mb-3"
        ></card>
      </div>

      <div class="d-flex flex-column flex-xl-row">
        <div v-background-2 class="w-100 mb-3 shadow-sm rounded p-3 mr-3">
          <div class="d-flex align-items-center mb-1">
            <BaseIcon name="gift" width="20" height="20" class="m-0"></BaseIcon>
            <h4 class="ml-2">Anniversaire(s)</h4>
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="c in data.collaborateurs_anniv" :key="c.id" class="d-flex align-items-center mr-3">
              <AppAvatar :url="c.collaborateur_avatar" propSize="2rem" ref="avatar" class="mr-2"></AppAvatar>
              <div>{{ c.collaborateur_nom }} {{ c.collaborateur_prenom }} ({{ c.collaborateur_age }}ans)</div>
            </div>
          </div>

          <div v-if="!data.collaborateurs_anniv || data.collaborateurs_anniv.length === 0">
            Aucun anniversaire aujourd'hui
          </div>
        </div>

        <div v-background-2 class="w-100 mb-3 shadow-sm rounded p-3">
          <div class="d-flex align-items-center mb-1">
            <BaseIcon name="gift" width="20" height="20" class="m-0"></BaseIcon>
            <h4 class="ml-2">Anniversaire(s) pro</h4>
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="c in data.collaborateurs_anniv_pro" :key="'pro_' + c.id" class="d-flex align-items-center mr-3">
              <AppAvatar :url="c.collaborateur_avatar" propSize="2rem" ref="avatar" class="mr-2"></AppAvatar>
              <div>
                {{ c.collaborateur_nom }} {{ c.collaborateur_prenom }} arrivé depuis
                {{ $moment().diff(c.anciennete, "years") }} ans
              </div>
            </div>
          </div>

          <div v-if="!data.collaborateurs_anniv_pro || data.collaborateurs_anniv_pro.length === 0">
            Aucun anniversaire professionnel aujourd'hui
          </div>
        </div>
      </div>

      <div>
        <div v-background-2 class="w-100 mb-3 shadow-sm rounded p-3">
          <div class="d-flex align-items-center mb-1">
            <BaseIcon name="gift" width="20" height="20" class="m-0"></BaseIcon>
            <h4 class="m-0 ml-2">Accompagnement(s) à réaliser(s)</h4>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-2">
            <h3 class="m-0 text-danger" v-if="data.stats_accompagnements > 10">{{ data.stats_accompagnements }}</h3>
            <h3 class="m-0 text-primary" v-if="data.stats_accompagnements < 10">{{ data.stats_accompagnements }}</h3>
            <ButtonCircle
              name="download"
              size="24"
              @click="getDataAccompagnementExport"
              :loading="loadingAccomps"
            ></ButtonCircle>
          </div>
        </div>
      </div>

      <div class="d-flex w-100">
        <div class="rounded shadow-sm mb-3 w-100" v-background-2>
          <div class="d-flex flex-column flex-md-row pl-3 pr-3 pt-3 mr-3">
            <span
              @click="tab = 'effectifs'"
              class="cursor-pointer ml-2 ml-md-0 mb-2"
              :class="{
                'text-muted-graph': tab !== 'effectifs',
                'border-bottom-graph': tab == 'effectifs',
              }"
            >
              <h4 class="mb-1">
                <strong>Effectifs</strong>
              </h4>
            </span>
            <span
              @click="tab = 'turnover'"
              class="cursor-pointer ml-2 ml-md-3 mb-2"
              :class="{
                'text-muted-graph': tab !== 'turnover',
                'border-bottom-graph': tab == 'turnover',
              }"
            >
              <h4 class="mb-1">
                <strong>Turn Over</strong>
              </h4>
            </span>
            <span
              @click="tab = 'societes'"
              class="cursor-pointer ml-2 ml-md-3 mb-2"
              :class="{
                'text-muted-graph': tab !== 'societes',
                'border-bottom-graph': tab == 'societes',
              }"
            >
              <h4 class="mb-1">
                <strong>Sociétés</strong>
              </h4>
            </span>
            <span
              @click="tab = 'etablissements'"
              class="cursor-pointer ml-2 ml-md-3 mb-2"
              :class="{
                'text-muted-graph': tab !== 'etablissements',
                'border-bottom-graph': tab == 'etablissements',
              }"
            >
              <h4 class="mb-1">
                <strong>Etablissements</strong>
              </h4>
            </span>
            <span
              @click="tab = 'entretiens'"
              class="cursor-pointer ml-2 ml-md-3 mb-2"
              :class="{
                'text-muted-graph': tab !== 'entretiens',
                'border-bottom-graph': tab == 'entretiens',
              }"
            >
              <h4 class="mb-1">
                <strong>Entretiens</strong>
              </h4>
            </span>
          </div>
          <chart-1 v-if="tab == 'effectifs'" :data="data.collaborateurs_effectifs_12"></chart-1>
          <chart-3 v-if="tab == 'turnover'" :data="data.collaborateurs_turn_over"></chart-3>
          <chart-2 v-if="tab == 'etablissements'" :data="data.collaborateurs_effectifs_sirets"></chart-2>
          <chart-4 v-if="tab == 'societes'" :data="data.collaborateurs_effectifs_societes"></chart-4>
          <chart-5
            v-if="tab == 'entretiens'"
            :data="data.stats_entretiens"
            @click="getDataEntretienExport()"
            :loading="loadingEntretiens"
          ></chart-5>
        </div>
      </div>
      <div class="d-none d-md-block">
        <Table1
          v-if="
            data.collaborateurs_effectifs_societes &&
            data.collaborateurs_effectifs_societes.contrats_mois_societes &&
            data.collaborateurs_effectifs_societes.contrats_mois_societes.data
          "
          :data="data.collaborateurs_effectifs_societes.contrats_mois_societes.data"
        ></Table1>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";

import Loader from "@/components/bases/Loader.vue";
import Chart1 from "@/components/collaborateurs/dashboard/Chart1.vue";
import Chart2 from "@/components/collaborateurs/dashboard/Chart2.vue";
import Chart3 from "@/components/collaborateurs/dashboard/Chart3.vue";
import Chart4 from "@/components/collaborateurs/dashboard/Chart4.vue";
import Chart5 from "@/components/collaborateurs/dashboard/Chart5.vue";

import Modal from "@/components/bases/Modal2.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import Card from "@/components/bases/Card.vue";
import Table1 from "@/components/collaborateurs/dashboard/Table1.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import AppAvatar from "@/components/bases/Avatar.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: {
    "qs-loader": Loader,
    Chart1,
    Chart2,
    Chart3,
    Chart4,
    Chart5,
    Modal,
    BaseForm,
    BaseInput,
    BaseInputDatePicker,
    Card,
    Table1,
    BaseIcon,
    AppAvatar,
    ButtonCircle,
  },
  data() {
    return {
      loading: false,
      chartLoaded: false,
      data: {},
      collaborateur: {},
      tab: "effectifs",
      feedback: {},
      contextDataContrat: {},
      password1: "",
      password2: "",
      entretiens: {},
      loadingEntretiens: false,
      loadingAccomps: false,
    };
  },
  methods: {
    ...mapActions({
      _storeCollaborateur: "collaborateurs/createCollaborateur",
    }),
    getDataStats() {
      this.loading = true;
      this.chartLoaded = false;
      this.$http
        .get("collaborateurs/stats")
        .then((response) => (this.data = response.data))
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
          this.chartLoaded = true;
        });
    },
    getDataEntretienExport() {
      this.loadingEntretiens = true;
      this.$http
        .get("collaborateurs/stats-entretiens-export")
        .then((response) => {
          let entretiens = response.data;
          let output = [];
          for (const [key, value] of Object.entries(entretiens)) {
            for (const [key2, value2] of Object.entries(value)) {
              value2.forEach((collab) => {
                output.push({
                  societe: key,
                  annee: key2,
                  id: collab.id,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                });
              });
            }
          }
          this.csvExport(output);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loadingEntretiens = false));
    },
    getDataAccompagnementExport() {
      this.loadingAccomps = true;
      this.$http
        .get("collaborateurs/stats-accompagnements-export")
        .then((response) => {
          let accomps = response.data;
          let output = [];
          accomps.forEach((acc) => {
            let resp = "";
            if (acc && acc.collaborateur_responsable && acc.collaborateur_responsable.collaborateur_nom) {
              resp = acc.collaborateur_responsable.collaborateur_nom;
            }

            if (acc && acc.collaborateur_responsable && acc.collaborateur_responsable.collaborateur_prenom) {
              resp = resp + " " + acc.collaborateur_responsable.collaborateur_prenom;
            }

            output.push({
              id: acc.id,
              ["collaborateur"]: acc.collaborateur_nom + " " + acc.collaborateur_prenom,
              ["responsable"]: resp,
            });
          });
          this.csvExport(output);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loadingAccomps = false));
    },
    storeCollaborateur() {
      this.loading = true;
      this.feedback = {};

      if (this.password1 === this.password2) {
        this.collaborateur.password = this.password1;
      } else {
        alert("les mots de passe saisies ne sont pas identiques");
        this.loading = false;
        return null;
      }

      this._storeCollaborateur(this.collaborateur)
        .then(() => {
          this.$refs.AddCollaborateur.hide();
          this.collaborateur = {};
        })
        .catch((error) => this.errHandler(error))
        .finally(() => {
          this.loading = false;
          this.chartLoaded = true;
          this.password1 = "";
          this.password2 = "";
        });
    },

    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [Object.keys(arrData[0]).join(";"), ...arrData.map((item) => Object.values(item).join(";"))]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    errHandler(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
  },
  computed: {
    ...mapMultiRowFields("collaborateurs", ["collaborateurs"]),
    IndicatorProgress() {
      let data = [];
      let indicator = {};
      if (this.data && this.data.collaborateurs_effectifs_12 && this.data.collaborateurs_effectifs_12.contrats_mois) {
        data = this.data.collaborateurs_effectifs_12.contrats_mois.dataset;
        indicator = data[data.length - 1] - data[data.length - 2];
      }

      if (indicator >= 0) {
        return { state: true, val: indicator };
      } else {
        return { state: false, val: indicator };
      }
    },
  },
  mounted() {
    this.getDataStats();
  },
};
</script>
<style lang="css">
.text-muted-graph {
  color: #b6bbbd;
}
.border-bottom-graph {
  border-bottom: 3px solid #eb9d00 !important;
}
</style>
