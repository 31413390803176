<template>
  <ContainerCard class="w-100">
    <div id="chart3" class="pl-3 pr-3" v-if="data">
      <apexchart type="line" height="360" :options="chartOptions" :series="series"></apexchart>
    </div>
  </ContainerCard>
</template>
<script>
import ContainerCard from "@/components/containers/ContainerCard.vue";

export default {
  components: {
    ContainerCard
  },
  data() {
    return {
      loading: false,
      chartLoaded: true
    };
  },
  props: {
    data: {
      Type: Object
    }
  },
  methods: {},
  computed: {
    series() {
      if (
        this.data &&
        this.data.stats_turn_over &&
        this.data.stats_turn_over.data
      ) {
        return this.data.stats_turn_over.data;
      }
      return [];
    },
    chartOptions() {
      if (
        this.data &&
        this.data.stats_turn_over &&
        this.data.stats_turn_over.labels
      ) {
        return {
          colors: this.data.stats_turn_over.data.map(x => x.color),
          chart: {
            background: 'transparent',
            toolbar: {
              show: false,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
              },
              autoSelected: "zoom"
            }
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          theme: {
            mode: this.$store.getters["colors/darkMode"] ? "dark" : "light"
          },
          stroke: {
            width: 4,
            curve: "smooth"
          },
          xaxis: {
            categories: this.data.stats_turn_over.labels
          },
          yaxis: {},
          fill: {
            opacity: 0.8
          },
          markers: {
            size: 4,
            strokeColors: "#fff",
            strokeWidth: 0,
            hover: {
              size: 7
            }
          },
          legend: {
            show: true,
            position: "right",
            showForZeroSeries: false,
            itemMargin: {
              horizontal: 0,
              vertical: 3
            },
            markers: {
              width: 10,
              height: 10
            }
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val;
              }
            }
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                legend: {
                  position: "bottom",
                  horizontalAlign: "left",
                  itemMargin: {
                    horizontal: 20,
                    vertical: 5
                  },
                  markers: {
                    width: 10,
                    height: 10
                  },
                  showForZeroSeries: false
                }
              }
            }
          ]
        };
      }
      return [];
    }
  }
};
</script>
<style lang="css"></style>
