<template>
  <table
    class="table table-borderless rounded shadow-sm w-100 m-0"
    :class="qwarkBorderTable"
    v-background-2
  >
    <thead>
      <tr class="border-bottom">
        <th scope="col">Société</th>
        <th scope="col">
          {{
          $moment()
          .subtract(5, "month")
          .format("MMM")
          }}
        </th>
        <th scope="col">
          {{
          $moment()
          .subtract(4, "month")
          .format("MMM")
          }}
        </th>
        <th scope="col">
          {{
          $moment()
          .subtract(3, "month")
          .format("MMM")
          }}
        </th>
        <th scope="col">
          {{
          $moment()
          .subtract(2, "month")
          .format("MMM")
          }}
        </th>
        <th scope="col">
          {{
          $moment()
          .subtract(1, "month")
          .format("MMM")
          }}
        </th>
        <th scope="col">{{ $moment().format("MMM") }}</th>
        <th scope="col">
          <BaseIcon
            name="activity"
            width="20"
            height="20"
            class="m-0 text-primary"
            :container="false"
          ></BaseIcon>
        </th>
      </tr>
    </thead>
    <tbody v-if="data">
      <tr v-for="s in data" :key="s.label">
        <td>{{ s.name }}</td>
        <td>{{ s.data[s.data.length - 6] }}</td>
        <td>{{ s.data[s.data.length - 5] }}</td>
        <td>{{ s.data[s.data.length - 4] }}</td>
        <td>{{ s.data[s.data.length - 3] }}</td>
        <td>{{ s.data[s.data.length - 2] }}</td>
        <td>{{ s.data[s.data.length - 1] }}</td>

        <td class>
          <span class="mr-1">
            {{
            (s.data[s.data.length - 1] - s.data[s.data.length - 2]).toString()
            }}
          </span>
          <BaseIcon
            name="arrow-up"
            width="14"
            height="14"
            class="m-0 text-success"
            v-if="s.data[s.data.length - 1] > s.data[s.data.length - 2]"
            :container="false"
          ></BaseIcon>
          <BaseIcon
            name="arrow-down"
            width="14"
            height="14"
            class="m-0 text-danger"
            v-if="s.data[s.data.length - 1] < s.data[s.data.length - 2]"
            :container="false"
          ></BaseIcon>
          <BaseIcon
            name="arrow-right"
            width="14"
            height="14"
            class="m-0 text-primary"
            v-if="s.data[s.data.length - 1] == s.data[s.data.length - 2]"
            :container="false"
          ></BaseIcon>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: {
    BaseIcon
  },
  data() {
    return {
      loading: false
    };
  },
  props: ["data"],
  methods: {},
  computed: {
    qwarkBorderTable: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "";
      } else {
        return "table-dark";
      }
    }
  }
};
</script>
<style lang="css"></style>
