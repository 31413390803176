<template>
  <ContainerCard class="w-100">
    <div id="chart1" class="pl-3 pr-3" v-if="data">
      <apexchart type="area" height="360" :options="chartOptions" :series="series"></apexchart>
    </div>
  </ContainerCard>
</template>
<script>
import ContainerCard from "@/components/containers/ContainerCard.vue";

export default {
  components: {
    ContainerCard
  },
  data() {
    return {
      loading: false,
      chartLoaded: true
    };
  },
  props: {
    data: {
      Type: Object
    }
  },
  methods: {},
  computed: {
    series() {
      if (
        this.data &&
        this.data.contrats_mois &&
        this.data.contrats_mois.dataset
      ) {
        return [
          {
            name: "Effectif",
            data: this.data.contrats_mois.dataset
          }
        ];
      }
      return [];
    },
    chartOptions() {
      if (
        this.data &&
        this.data.contrats_mois &&
        this.data.contrats_mois.dataset
      ) {
        return {
          colors: ["#EB9D00"],
          chart: {
            background: 'transparent',
            toolbar: {
              show: false,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
              },
              autoSelected: "zoom"
            }
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          theme: {
            mode: this.$store.getters["colors/darkMode"] ? "dark" : "light"
          },
          stroke: {
            width: 4,
            curve: "smooth"
          },
          xaxis: {
            categories: this.data.contrats_mois.labels
          },
          fill: {
            opacity: 0.8
          },
          markers: {
            size: 4,
            strokeColors: "#fff",
            strokeWidth: 0,
            hover: {
              size: 7
            }
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val + " collaborateurs";
              }
            }
          }
        };
      }
      return [];
    }
  }
};
</script>
<style lang="css">
</style>